import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useSelector } from "react-redux";
import {
  draPartnerId,
  englishStarCourseId,
  env,
  partnerId,
  esolCourseIds,
} from "api/Consts";
import EnglishStarAdvertisement from "./dashboardcarousel/EnglishStarAdvertisement";
import PurchaseDemoedCourse from "./dashboardcarousel/PurchaseDemoedCourse";
import { NextArrow, PrevArrow } from "./dashboardcarousel/Arrows";
import courseSvc from "services/Course";

import EsolAdvertisement from "./dashboardcarousel/EsolAdvertisement";
const SLIDE_TYPES = {
  purchase_demoed_course: "purchase-demoed-course",
  english_star_ad: "english-star-ad",
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 8000,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

export default function SimpleSlider(props) {
  const auth = useSelector(({ auth }) => auth);

  const [attendedDemos, activeCourses] = useSelector(({ course }) => [
    course.user_demo_batches,
    course.active_courses,
  ]);

  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const slides = [];
    const sortedBanners = esolCourseIds.sort((a, b) => {
      if (a.course_id === auth?.courseId) return -1;
      if (b.course_id === auth?.courseId) return 1;
      return 0;
    });

    sortedBanners
      .filter(({ course_id }) => !activeCourses[course_id])
      .forEach(({ course_id, bannerPath }) => {
        slides.push({
          type: SLIDE_TYPES.non_active_course_ad,
          courseId: course_id,
          bannerPath,
        });
      });

    if (attendedDemos) {
      (async () => {
        let unpurchased = {},
          isEnglishDemoed = false,
          isEnglishPurchased = !!activeCourses[englishStarCourseId];

        attendedDemos
          .filter((bs) => new Date() > new Date(bs.endto_date))
          .forEach((batch_slot) => {
            if (batch_slot.batch.course_id === englishStarCourseId)
              isEnglishDemoed = true;

            const purchasedCourse = activeCourses[batch_slot.batch.course_id];
            if (!purchasedCourse)
              unpurchased[batch_slot.batch.course_id] = true;
          });

        const unpurchasedCoursesFullDetails = await courseSvc.getCoursesById(
          Object.keys(unpurchased)
        );

        Object.keys(unpurchased).map((courseId) => {
          const course = unpurchasedCoursesFullDetails.find(
            (c) => c.id == courseId
          );
          slides.push({
            type: SLIDE_TYPES.purchase_demoed_course,
            course,
          });
        });

        if (!isEnglishDemoed && !isEnglishPurchased)
          slides.push({ type: SLIDE_TYPES.english_star_ad });

        setSlides(slides);
      })();
    } else {
      setSlides(slides);
    }
  }, [attendedDemos, activeCourses]);

  return (
    <div className="rounded-md p-6 mt-4 w-full">
      {/* <h3 className="font-poppins font-bold text-2xl text-japanese_indigo my-4">
        Advertised Courses
      </h3> */}

      {partnerId !== draPartnerId && (
        <Slider {...settings}>
          {/* {couponIds &&
          couponIds.map((data) => {
            return <div>{data.user_id}</div>;
          })} */}
          {slides.map((slide) => {
            if (slide.type === SLIDE_TYPES.purchase_demoed_course) {
              return <PurchaseDemoedCourse {...slide} />;
            } else if (slide.type === SLIDE_TYPES.english_star_ad)
              return <EnglishStarAdvertisement />;
          })}
        </Slider>
      )}
    </div>
  );
}

// API

// TO REVERT
// const env = ""; // comment before push
import bannerEmpolyment from "../assets/esol/bannerEsol1.png";
import bannerEducation from "../assets/esol/bannerEsolEducation.png";
export let env = {}; // uncomment before push
export const draPartnerId = 71;
export const { partnerId } = require("../partnerData.json");

if (process.env.REACT_APP_BUILD_ENV === "production") {
  env.isProduction = true;
} else if (process.env.REACT_APP_BUILD_ENV === "staging") {
  env.isStaging = true;
} else {
  env.isLocal = true;
}

export let domain = "";
export let moodleCourseUrl = "";
export let jwt_key = "D1774F49-C82F-498A-8E14-8CAF65B881F3";
export let jwt_url = "https://quartzweb.nocn.org.uk/QuartzWeb_Preview";
if (env.isProduction) {
  domain = "https://webappapi.skillstrainer.in";
  if (process.env.REACT_APP_USE_LOCAL_API)
    domain = "http://webappapi.skillstrainer-dev.co";
  moodleCourseUrl = "https://lms.skillstrainer.in/moodle";
} else if (env.isStaging) {
  domain = "https://webapp.skillsscale.in";
  moodleCourseUrl = "https://lms.skillstrainer.in/replicaa";
} else {
  domain = "http://webappapi.skillstrainer-dev.co";
  moodleCourseUrl = "https://lms.skillstrainer.in/replicaa";
}

// GraphQL
export let graphqlUri = "";
export let graphqlWsUri = "";

if (env.isProduction) {
  graphqlUri = "https://graphql.skillstrainer.in/v1/graphql";
  graphqlWsUri = "wss://graphql.skillstrainer.in/v1/graphql";
} else if (env.isStaging) {
  graphqlUri = "https://hasura.skillsscale.in/v1/graphql";
  graphqlWsUri = "wss://hasura.skillsscale.in/v1/graphql";
} else {
  graphqlUri = "http://localhost:9099/v1/graphql";
  graphqlWsUri = "wss://localhost:9099/v1/graphql";
}

// SCORM server
export let scormServerBaseUrl = "";

if (env.isProduction) {
  scormServerBaseUrl = "https://scorms.skillstrainer.in";
} else if (env.isStaging) {
  scormServerBaseUrl = "https://scorms.skillsscale.in";
} else {
  scormServerBaseUrl = "http://scorms.skillstrainer-dev.co";
}

// SCORM Server

export let scormServerUri = "";

if (env.isProduction) {
  scormServerUri = "https://scorms.skillstrainer.in";
} else if (env.isStaging) {
  scormServerUri = "https://scorms.skillsscale.in";
} else {
  scormServerUri = "http://scorms.skillstrainer-dev.co";
}

// Moodle
export let moodleUri = "";

if (env.isProduction) {
  moodleUri = "https://lms.skillstrainer.in/moodle";
} else {
  moodleUri = "https://lms.skillstrainer.in/replicaa";
}

// Marketing website
export let marketingHost = "https://skillstrainer.in";

// S3 base url
export let s3BaseUrl;

if (env.isProduction)
  s3BaseUrl = "https://adminskillstrainerprod.s3.ap-south-1.amazonaws.com";
else if (env.isStaging)
  s3BaseUrl = "https://adminskillstrainer.s3.ap-south-1.amazonaws.com";
else s3BaseUrl = "https://adminskillstrainer.s3.ap-south-1.amazonaws.com";

// Quiz Image base url
export let s3QuizImgBaseUrl;

if (env.isProduction)
  s3QuizImgBaseUrl =
    "https://adminskillstrainerprod.s3.ap-south-1.amazonaws.com";
else if (env.isStaging)
  s3QuizImgBaseUrl = "https://adminstagingploads.s3.amazonaws.com";
else s3QuizImgBaseUrl = "https://adminstagingploads.s3.amazonaws.com";

// QR code generation API URL
export const qrCodeGenerationApiUrl =
  "https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=";

// Auth Providers
export let providers = {
  google: require("../assets/data/google-credentials.json")[
    !env.isLocal ? "prod" : "test"
  ],
  facebook: require("../assets/data/fb-credentials.json")[
    !env.isLocal ? "prod" : "test"
  ],
};

export let RAZORPAY_KEY_ID = "";
if (env.isProduction) {
  RAZORPAY_KEY_ID = "rzp_live_Wmjbt3NrYldCcq";
} else if (env.isStaging) {
  RAZORPAY_KEY_ID = "rzp_test_xL98of2uJRdFiI";
} else {
  RAZORPAY_KEY_ID = "rzp_test_xL98of2uJRdFiI";
}

export let SCHOOL_QUALIFICATIONS_HEIRARCHY = [];

SCHOOL_QUALIFICATIONS_HEIRARCHY = [
  // 1st
  [{ name: "1st", id: 10 }],
  [
    // 2nd
    { name: "2nd", id: 11 },
  ],
  [
    // 3rd
    { name: "3rd", id: 12 },
  ],
  [
    // 4th
    { name: "4th", id: 13 },
  ],
  [
    // 5th
    { name: "5th", id: 1 },
  ],
  [
    // 6th
    { name: "6th", id: 14 },
  ],
  [
    // 7th
    { name: "7th", id: 15 },
  ],
  [
    // 8th
    { name: "8th", id: 2 },
  ],
  [
    // 9th
    { name: "9th", id: 3 },
  ],
  [
    // 10th
    { name: "10th", id: 4 },
  ],
  [
    // 11th
    { name: "11th", id: 5 },
  ],
  [
    // 12th
    { name: "12th", id: 6 },
    { name: "12th (Science)", id: 1963 },
    { name: "12th (Commerce)", id: 1964 },
    { name: "12th (Arts)", id: 1965 },
  ],
];

// Constants
export const GENDER = {
  MALE: "0",
  FEMALE: "1",
  OTHER: "2",
};

export const GENDER_NAMES = {
  [GENDER.MALE]: "Male",
  [GENDER.FEMALE]: "Female",
  [GENDER.OTHER]: "Other",
};

export const SOCIAL_CATEGORY = {
  GENERAL: "0",
  SC: "1",
  ST: "2",
  OBC: "3",
  DIFFERENTLY_ABLED: "4",
  ORHPAN: "5",
};

export const SOCIAL_CATEGORY_NAMES = {
  [SOCIAL_CATEGORY.GENERAL]: "General",
  [SOCIAL_CATEGORY.SC]: "SC",
  [SOCIAL_CATEGORY.ST]: "ST",
  [SOCIAL_CATEGORY.OBC]: "OBC",
  [SOCIAL_CATEGORY.DIFFERENTLY_ABLED]: "Differently Abled",
  [SOCIAL_CATEGORY.ORHPAN]: "Orphan",
};

export const DOCUMENT_TYPE = {
  AADHAR: "0",
  PASSPORT: "1",
  RATION: "2",
  DRIVING_LICENSE: "3",
  INCOME_CERT: "4",
  FAMILY_INCOME_CERT: "9",
  ACADEMIC_CERT: "5",
  RECOMMENDATION_CERT_FROM_INSTITUTE: "6",
  RECOMMENDATION_CERT_FROM_ROTARY: "10",
  EXTRA_CURRICULAR_CERT: "7",
  DOCUMENT_PROOF: "8",
  DOCUMENT_PROOF_TECHINCAL: "12",
  OTHER_CERT: "11",
  OTHER: "10",
};

export const FAMILY_MEMBER = {
  FATHER: "0",
  MOTHER: "1",
  BROTHER: "2",
  SISTER: "3",
};

export const MARKING_TYPE = {
  grade: "grade",
  percentage: "percentage",
};

export const MARKING_TYPE_NAMES = {
  [MARKING_TYPE.grade]: "Grade",
  [MARKING_TYPE.percentage]: "Percentage",
};

export const STUDENT_QUAL_TYPE = {
  FIFTH: "0",
  EIGHTH: "1",
  NINTH: "2",
  TENTH: "3",
  ELEVENTH: "4",
  TWELFTH: "5",
  BACHELOR: "6",
  MASTER: "7",
  PHD: "8",
};

export const QUALIFICATION_ID_TO_TYPE = {
  1: 0,
  2: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
  7: 6,
  8: 7,
  9: 8,
};

export const QUALIFICATION_NAMES = {
  [STUDENT_QUAL_TYPE.FIFTH]: "5th Standard",
  [STUDENT_QUAL_TYPE.EIGHTH]: "8th Standard",
  [STUDENT_QUAL_TYPE.NINTH]: "9th Standard",
  [STUDENT_QUAL_TYPE.TENTH]: "10th Standard",
  [STUDENT_QUAL_TYPE.ELEVENTH]: "11th Standard",
  [STUDENT_QUAL_TYPE.TWELFTH]: "12th Standard",
  [STUDENT_QUAL_TYPE.BACHELOR]: "Bachelor",
  [STUDENT_QUAL_TYPE.MASTER]: "Master",
  [STUDENT_QUAL_TYPE.PHD]: "PhD (or similar)",
};

export const ACEDEMIC_QUALIFICATION_DETAILS = {
  [STUDENT_QUAL_TYPE.FIFTH]: { qualification_id: "1", label: "5th" },
  [STUDENT_QUAL_TYPE.EIGHTH]: { qualification_id: "2", label: "8th" },
  [STUDENT_QUAL_TYPE.NINTH]: { qualification_id: "3", label: "9th" },
  [STUDENT_QUAL_TYPE.TENTH]: { qualification_id: "4", label: "10th" },
  [STUDENT_QUAL_TYPE.ELEVENTH]: { qualification_id: "5", label: "11th" },
  [STUDENT_QUAL_TYPE.TWELFTH]: {
    key: "twelve",
    qualification_id: "6",
    label: "12th",
  },
  [STUDENT_QUAL_TYPE.BACHELOR]: {
    key: "bachelors",
    qualification_id: "7",
    label: "Bachelors",
  },
  [STUDENT_QUAL_TYPE.MASTER]: {
    key: "masters",
    qualification_id: "8",
    label: "Masters",
  },
  [STUDENT_QUAL_TYPE.PHD]: { key: "phd", qualification_id: "9", label: "PHD" },
};

export const TECHNICAL_QUALIFICATION_TYPE = {
  DIPLOMA: "T0",
  ITI: "T1",
  NSQF: "NSQF",
  OTHER: "T2",
};

export const TECHNICAL_QUALIFICATION_NAMES = {
  [TECHNICAL_QUALIFICATION_TYPE.DIPLOMA]: "Diploma",
  [TECHNICAL_QUALIFICATION_TYPE.ITI]: "ITI",
  [TECHNICAL_QUALIFICATION_TYPE.NSQF]: "NSQF Certification",
  [TECHNICAL_QUALIFICATION_TYPE.OTHER]: "Other",
};

export const EDUCATION_INSTITUTE_TYPE = {
  COLLEGE: "0",
  ITI: "1",
  DIPLOMA: "2",
};

export const CONTACT_PERSON_TYPE = {
  TEACHER: "0",
  PRINCIPAL: "1",
  OTHER: "2",
};

export const ORGANISATION_TYPE = [
  { label: "Sector Skill Council", value: "sector_skill_council" },
  { label: "College/ Polytechnic", value: "college_polytechnic" },
  { label: "Training/ Coaching Center", value: "training_coaching_center" },
  { label: "Social Organisation/NGO", value: "social_organisation_ngo" },
  { label: "ITI", value: "iti" },
  { label: "Govt. Department", value: "govt._department" },
  { label: "Corporate", value: "corporate" },
  { label: "University", value: "university" },
  { label: "School", value: "school" },
  { label: "Other", value: "other" },
];

export const CONTACT_PERSON_NAMES = {
  [CONTACT_PERSON_TYPE.PRINCIPAL]: "Principal",
  [CONTACT_PERSON_TYPE.TEACHER]: "Teacher",
  [CONTACT_PERSON_TYPE.OTHER]: "Other",
};

export const PROVIDERS = {
  GOOGLE: "google",
  FACEBOOK: "facebook",
};

export const COURSE_STATUS = {
  ONGOING: "ongoing",
  COMPLETED: "completed",
};

export const DATE_TIME_FORMATS = {
  STANDARD_DATE: "DD-MM-YYYY",
  STANDARD_DATE_TIME: "DD-MM-YYYY HH:mm:ss",
  DEMO_BATCH_SLOTS_DATE: "DD MMMM YY",
};

export const ANNUAL_INCOME_SLABS = {
  1: "Less than 1L",
  2: "1L - 2.5L",
  3: "2.5L - 5L",
  4: "5L and above",
};

export const MULTI_SELECT_JOBS_FILTERS = {
  LANGUAGES: "Languages",
  SOCIAL_CATEGORIES: "Social_Categories",
  PARTNER: "Partner",
  QUALIFICATIONS: "Qualifications",
  SKILLS: "Skills",
  COUNTRY: "Country",
  CITIES: "Cities",
  SEARCH: "Search",
  SALARY: "Salary",
  SECTOR: "Sector",
};

export const JOB_CHECKS_PAGES = {
  LANGUAGES: 2,
  SKILLS: 7,
  QUALIFICATIONS: 5,
};

export const englishStarCourseId = 31;

export const USER_ACCOUNT_TYPE = ["bank_account", "vpa"];

export const phoneNumberRegex =
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const emailRegex =
  /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/;

export const COURSES_REF = env.isProduction
  ? {
      SKILL_YATRA: [178, 205],
    }
  : {
      SKILL_YATRA: [5],
    };

export const asdmConfig = {
  partnerId: 51,
  projectId: 18,
};

export const englishStar = env.isProduction
  ? {
      course_id: 31,
    }
  : {
      course_id: 91,
    };

export const englishStartSchemeId = env.isProduction
  ? {
      scheme_id: 10,
    }
  : {
      scheme_id: 36,
    };
export const esolCourseIds = env.isProduction
  ? [
      { course_id: 2, bannerPath: bannerEmpolyment },
      { course_id: 1, bannerPath: bannerEmpolyment },
    ]
  : [
      { course_id: 2, bannerPath: bannerEmpolyment },
      { course_id: 1, bannerPath: bannerEducation },
    ];

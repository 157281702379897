/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useRouteMatch } from "react-router";
import Login from "../pages/components/LandingHomePage/Login";
import SignUp from "../pages/components/LandingHomePage/SignUp";
import Container from "../components/Container";
import { useSelector } from "react-redux";
import landingEsol from "../assets/esol/Group 1000004959.png";
import { esolCourseIds } from "api/Consts";
import partnerData from "../../src/partnerData.json";
import { draPartnerId, partnerId } from "api/Consts";
import { contactSupport } from "components/shared/navbar";

const LandingHomePage = () => {
  const { path } = useRouteMatch();
  const auth = useSelector((state) => state.auth);
  const { notice, courseId } = auth;
  return (
    <div>
      <Container className="z-1">
        <div
          className={`flex ${
            partnerId !== draPartnerId ? "flex-col-reverse" : "flex-col"
          } md:flex-row items-center justify-center`}
        >
          <div className="col-span-3 md:col-span-2 mt-10 md:mt-2 md:pr-10 w-full md:w-1/2 lg:w-2/3 z-1 h-full">
            {partnerId !== draPartnerId ? (
              <>
                {(courseId && courseId === esolCourseIds[0].course_id) ||
                (courseId && courseId === esolCourseIds[1].course_id) ? (
                  <div
                    style={{
                      background:
                        "linear-gradient(304.89deg, #002A50 2.46%, #00629D 96.52%)",
                      backgroundImage: "url(./onboarding-leftpane.png)",
                    }}
                    className="h-full p-4"
                  >
                    <img src={landingEsol} />
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundImage: "url(./onboarding-leftpane.png)",
                    }}
                    className="h-full p-4"
                  >
                    <h1 className="text-2xl lg:text-3xl font-semibold my-4 text-japanese_indigo custom-lh-22 text-center md:text-left">
                      Welcome to SkillsTrainer!
                    </h1>
                    <div
                      className={`w-20 border-2 bg-${
                        partnerId || "default"
                      }-primary border-${
                        partnerId || "default"
                      }-primary  h-0 my-6 text-transparent`}
                    >
                      .
                    </div>
                    <h1 className="font-semibold text-japanese_indigo text-xl lg:text-xl">
                      This Could Be You!
                    </h1>
                    <div className="flex flex-col lg:flex-row my-3">
                      <div className="bg-white flex flex-col p-1 rounded-md my-3 lg:my-0 mx-0 lg:mx-3">
                        <img
                          src="./testimonial-1.png"
                          className="rounded-md"
                          alt="test-1"
                        />
                        <div className="my-2 mx-1">
                          <p className="text-sm">
                            Pooja, 20 yrs, Delhi Retail Trainees
                          </p>
                          <p className="text-sm">
                            Associate @ Reliance Retail,
                          </p>
                          <p className="text-sm">
                            Income:{" "}
                            <span className={`text-default-primary`}>
                              ₹15000+
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="bg-white flex flex-col p-1 rounded-md mt-0 lg:mt-6 mx-0 lg:mx-3">
                        <img
                          src="./testimonial-2.png"
                          className="rounded-md"
                          alt="test-2"
                        />
                        <div className="my-2 mx-1">
                          <p className="text-sm">
                            Amit, 20 yrs, Delhi Retail Trainees
                          </p>
                          <p className="text-sm">
                            Associate @ Reliance Retail,
                          </p>
                          <p className="text-sm">
                            Income:{" "}
                            <span className={`text-default-primary`}>
                              ₹18000+
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="bg-white flex flex-col p-1 rounded-md mx-0 lg:mx-3 my-3 lg:my-0">
                        <img
                          src="./testimonial-3.png"
                          className="rounded-md"
                          alt="test-3"
                        />
                        <div className="my-2 mx-1">
                          <p className="text-sm">
                            Aditi, 24 yrs, Delhi Retail Trainees
                          </p>
                          <p className="text-sm">
                            Associate @ Reliance Retail,
                          </p>
                          <p className="text-sm">
                            Income:{" "}
                            <span
                              className={`text-${
                                partnerId || "default"
                              }-primary`}
                            >
                              ₹15000+
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div
                style={{
                  backgroundImage: "url(./onboarding-leftpane.png)",
                }}
                className="h-full p-4"
              >
                <h1 className="text-2xl lg:text-3xl font-semibold my-4 text-japanese_indigo custom-lh-22 text-center md:text-left">
                  Welcome to Debt Recovery Agent (DRA) LMS!
                </h1>
                <p>
                  To proceed, please use the credentials you have received in
                  your <b>Welcome E-mail</b>.
                </p>
                <p>
                  If you are facing any trouble logging in, feel free to{" "}
                  <button
                    onClick={contactSupport}
                    className="text-71-secondary underline"
                  >
                    Contact Support
                  </button>
                </p>
              </div>
            )}
          </div>
          <div className="w-full md:w-1/2 xl:w-1/3 flex items-center mt-5">
            {draPartnerId === partnerId ? (
              path === "/signup" ? (
                <SignUp notice={notice} />
              ) : (
                <Login notice={notice} />
              )
            ) : path === "/login" ? (
              <Login notice={notice} />
            ) : (
              <SignUp notice={notice} />
            )}
          </div>
        </div>{" "}
      </Container>
      {partnerId !== draPartnerId && (
        <div
          className={`bg-${
            partnerId || "default"
          }-light filter blur-3xl w-full md:w-1/2 lg:w-1/3 h-64 absolute mt-64 right-40 bottom-0 z-0`}
        />
      )}
      {/*
        Mobile menu, show/hide based on mobile menu state.

        Entering: "duration-200 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
      */}
      <div className="mobile-menu absolute top-0 inset-x-0 p-2 duration-200 ease-out transition transform origin-top-right hidden">
        <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between">
              <div>
                <img
                  className="h-8 w-auto sm:h-10"
                  src="/images/logo.svg"
                  alt=""
                />
              </div>
              <div className="align-middle pl-5 mt-1 text-xl font-medium text-japanese_indigo">
                {partnerData?.partnerDisplayText}
              </div>
              <div className="-mr-2">
                <button
                  type="button"
                  className="close bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span className="sr-only">Close menu</span>
                  {/* Heroicon name: outline/x */}
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="mt-6">
              <nav className="grid gap-y-8">
                <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                  <a
                    href="#"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    Home
                  </a>
                  <a
                    href="#"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    About us
                  </a>
                  <a
                    href="#"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    Courses
                  </a>
                  <a
                    href="#"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    Blogs
                  </a>
                </div>
              </nav>
            </div>
          </div>
          <div className="py-6 px-5 space-y-6">
            <div>
              <a
                href="#"
                className={`w-full whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-${
                  partnerId || "default"
                }-primary rounded-md text-base font-medium  text-${
                  partnerId || "default"
                }-primary bg-${partnerId || "default"}-light hover:bg-${
                  partnerId || "default"
                }-primary hover:text-white`}
              >
                Joing for free
              </a>
              <p className="mt-6 text-center text-base font-medium text-gray-500">
                Already have an account?
                <a
                  href="#"
                  className={`font-medium text-${
                    partnerId || "default"
                  }-primary hover:text-${partnerId || "default"}-primary`}
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHomePage;
